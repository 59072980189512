import Vue from "vue";
import Vuex from "vuex";
require("es6-promise/auto");

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

interface State {}

export default new Vuex.Store<State>({
    modules: {},
    strict: debug,
});
