import Vue from "vue";
import VueAnalytics from "vue-analytics";
import router from "./router";
import VueGtag from "vue-gtag";

//GA4 measurement ID: G-BR01RW305E
Vue.use(VueGtag, { config: { id: "UA-109281152-1" } }, router);

Vue.use(VueAnalytics, {
    id: "UA-109281152-1",
    router,
});
